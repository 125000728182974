import {initReactI18next} from 'react-i18next';
import format from 'date-fns/format';
import formatDistance from 'date-fns/formatDistance';
import isAfter from 'date-fns/isAfter';
import isToday from 'date-fns/isToday';
import dateLocaleEl from 'date-fns/locale/el';
import dateLocaleEn from 'date-fns/locale/en-US';
import i18next from 'i18next';
import applicationFormMessagesEl from 'shared/components/applicationForm/i18n/messages/el';
import applicationFormMessagesEn from 'shared/components/applicationForm/i18n/messages/en';
import sharedMessagesEl from 'shared/ui/locales/el';
import sharedMessagesEn from 'shared/ui/locales/en';
import {getAvailableLanguageCodes} from 'job-board/shared/utils/locale';
import messagesEl from './messages/el.json';
import messagesEn from './messages/en.json';

const DATE_LOCALES: Record<string, Locale> = {
  el: dateLocaleEl,
  en: dateLocaleEn
};

const SHARED_MESSAGES: Record<string, Record<string, unknown>> = {
  el: sharedMessagesEl,
  en: sharedMessagesEn
};

const APPLICATION_FORM_MESSAGES: Record<string, Record<string, unknown>> = {
  el: applicationFormMessagesEl,
  en: applicationFormMessagesEn
};

const DEFAULT_LANG = 'en';

const availableLanguageCodes = getAvailableLanguageCodes();
const supportedLngs = availableLanguageCodes.length > 0 ? availableLanguageCodes : [DEFAULT_LANG];

void i18next.use(initReactI18next).init({
  fallbackLng: DEFAULT_LANG,
  supportedLngs,
  lng: document.documentElement.lang || DEFAULT_LANG,
  load: 'languageOnly',
  parseMissingKeyHandler: () => '',
  resources: {
    el: {
      translation: messagesEl
    },
    en: {
      translation: messagesEn
    }
  },
  returnNull: false
});

const copies = window.jobBoard?.copies ?? {};

supportedLngs.forEach(lang => {
  i18next.addResourceBundle(lang, 'translation', {'page-data': copies[lang] ?? copies}, true, true);
  i18next.addResourceBundle(lang, 'translation', APPLICATION_FORM_MESSAGES[lang]);
  i18next.addResourceBundle(lang, 'shared', SHARED_MESSAGES[lang]);
});

i18next.getSharedResources = (locale = i18next.language) => {
  const localeSharedMessages = SHARED_MESSAGES[locale];

  if (!localeSharedMessages) {
    return;
  }

  const sharedLocales = Object.freeze(Object.keys(SHARED_MESSAGES));

  const chosenLanguage = sharedLocales.find(sharedLocale => sharedLocale === locale);

  // eslint-disable-next-line @typescript-eslint/no-unsafe-return
  return i18next.getResourceBundle(chosenLanguage || DEFAULT_LANG, 'shared');
};

const getOverriddenFormattedDistance = (): Record<string, string> => {
  const fewHoursAgoMessage = i18next.t('job.posted.few-hours-ago');

  return {
    aboutXHours: fewHoursAgoMessage,
    halfAMinute: fewHoursAgoMessage,
    lessThanXMinutes: fewHoursAgoMessage,
    lessThanXSeconds: fewHoursAgoMessage,
    xMinutes: fewHoursAgoMessage,
    xSeconds: fewHoursAgoMessage
  };
};

const formatDistanceOverride =
  (defaultFormatDistance: Locale['formatDistance']) =>
  (
    token: string,
    count: string,
    options?: {
      addSuffix?: boolean;
      comparison?: number;
      includeSeconds?: boolean;
      locale?: Locale;
    }
  ): string => {
    const overriddenFormattedDistance = getOverriddenFormattedDistance()[token];

    if (overriddenFormattedDistance && options?.addSuffix && options?.comparison && options.comparison <= 0) {
      return overriddenFormattedDistance;
    }

    return (defaultFormatDistance as (...args: unknown[]) => string)(token, count, options);
  };

i18next.localizedFormat = (date: Date | number, dateFormat: string, options = {}): string =>
  format(date, dateFormat, {...options, locale: DATE_LOCALES[i18next.language]});

i18next.formatDistance = (date: Date | string): string => {
  if (!date) {
    return '';
  }

  date = date instanceof Date ? date : new Date(date);

  if (isToday(date)) {
    return i18next.t('shared.text.today');
  }

  const dateLocale = DATE_LOCALES[i18next.language] ?? DATE_LOCALES[DEFAULT_LANG];

  const formatDistanceLocale = {
    ...dateLocale,
    formatDistance: formatDistanceOverride(dateLocale.formatDistance)
  };

  const now = Date.now();

  return formatDistance(isAfter(date, now) ? now : date, now, {
    addSuffix: true,
    locale: formatDistanceLocale
  });
};

i18next.localizedFormatDistance = (firstDate, secondDate, options = {}): string =>
  formatDistance(firstDate, secondDate, {...options, locale: DATE_LOCALES[i18next.language]});

export default i18next;
