export default {
  candidate_card: {
    bridge: {
      pill: {
        label: 'Bridge candidate',
        tooltip:
          "Bridge is a job matching service that connects a company's former employees with relevant opportunities at new organizations."
      },
      dropdown: {
        title: 'What is a Bridge candidate?',
        body: "Bridge candidates are jobseekers that have joined their former company's  <a href='https://help.workable.com/hc/en-us/articles/360046065794' target='_blank'>Workable Bridge program</a>. When a company has layoffs they can add former employees to Bridge to help them find a new job. Thus, jobseekers who are out of work through no fault of their own get notified about jobs posted through Workable for which they are a good match. They can then choose to apply."
      }
    },
    common: {
      at: 'at',
      disqualified: 'Disqualified',
      talent_pool: {
        title: 'Talent Pool'
      }
    },
    rating: {
      breakdown_tooltip: {
        title: {
          hidden_evaluation: 'Hidden evaluation',
          evaluation_count: '{count} Evaluation',
          evaluation_count_one: '{count} Evaluation',
          evaluation_count_other: '{count} Evaluations',
          previous_stage_evaluation: 'Evaluation from previous stage'
        },
        hidden_evaluation_message:
          'To reduce bias, all evaluations submitted at this stage are hidden until you complete this candidate’s evaluation.'
      },
      simple: {
        thumbs: {
          a18y_label: 'negative',
          a18y_label_0: 'negative',
          a18y_label_1: 'positive',
          a18y_label_2: 'definite'
        },
        stars: {
          a18y_label: '{score} out of {total} stars'
        },
        numbers: {
          a18y_label: '{score} out of {total}'
        }
      }
    },
    siblings: {
      also_in: 'Also in',
      dropdown: {
        btn_title: '{count} other job',
        btn_title_one: '{count} other job',
        btn_title_other: '{count} other jobs',
        header: {
          title: 'Other job',
          title_one: 'Other job',
          title_other: 'Other jobs',
          subtitle: '{name} is also in the following job:',
          subtitle_one: '{name} is also in the following job:',
          subtitle_other: '{name} is also in the following jobs:'
        },
        sibling_candidate: {
          member_not_in_hiring_team: 'You are not in the hiring team of this job'
        }
      },
      error_loading: {
        primary: 'Failed to fetch data',
        secondary: 'Try modifying your existing search or run a new search.',
        button: 'Retry'
      }
    },
    source: {
      ai: {
        likelihood_to_move: {
          tooltip: 'High probability that this candidate would like to explore new job opportunities',
          label: 'Likelihood to move'
        },
        updated_profile: {
          tooltip: 'Some candidate details have changed since they last applied for a job.',
          label: 'Updated profile'
        }
      }
    }
  },
  candidate_profile: {
    avatar: 'Photo from {type}',
    evaluation: {
      more_members: {
        one: 'and 1 more member',
        other: 'and {count} more members'
      }
    },
    candidate_overview: {
      title: 'Candidate overview',
      blank_slate: {
        heading: 'No activities yet',
        body: 'Upcoming events, recent comments, messages and more, will appear here.'
      },
      info_messages: {
        offer_pending: {
          title: 'Offer pending',
          message: 'Waiting for approval',
          action_label: 'Review offer'
        },
        responded_to_campaign: {
          title: 'Responded to campaign',
          message: 'Send an email to get the conversation started.',
          action_label: 'Send an email'
        },
        add_evaluation: {
          title: 'Evaluation reminder',
          message_call_event: 'Add your evaluation for the call with {candidateName} on {eventDate}.',
          message_interview_event: 'Add your evaluation for the interview with {candidateName} on {eventDate}.',
          message_meeting_event: 'Add your evaluation for the meeting with {candidateName} on {eventDate}.',
          message_internal_event: 'Add your evaluation for the internal meeting for {candidateName} on {eventDate}.',
          action_label: 'Add evaluation',
          action_label_scorecard: 'Complete scorecard'
        },
        upcoming_event: {
          title: 'Next up',
          event_type: {
            call_event: 'call',
            interview_event: 'interview',
            meeting_event: 'meeting',
            internal_event: 'internal meeting'
          },
          event_date: '{startDay} at {startTime} - {endTime}',
          event_date_next_day: '{startDay} at {startTime} - {endTime} (next day)',
          message: {
            scheduled: 'Scheduled <0>{eventType}</0> on {eventDate}.',
            scorecard: 'Use scorecard questions for your {eventType}.',
            recommended_questions: 'View potential interview questions based on their personality assessment.'
          },
          action_label: 'Add evaluation',
          action_label_scorecard: 'View scorecard',
          action_label_questions: 'View questions'
        }
      },
      siblings: {
        header: {
          title: 'Other job',
          title_one: 'Other job',
          title_other: 'Other jobs ({count})',
          subtitle: '{name} is also in the following job:',
          subtitle_one: '{name} is also in the following job:',
          subtitle_other: '{name} is also in the following jobs:'
        }
      },
      message: {
        files_one: '+ 1 more file',
        files_other: '+ {count} more files'
      },
      latest_comments: {
        header: {
          title_one: 'Latest comment',
          title_other: 'Latest comments',
          view_all: 'View all'
        }
      },
      latest_messages: {
        header: {
          title_one: 'Latest message',
          title_other: 'Latest messages',
          view_all: 'View all'
        }
      },
      followers: {
        header: 'Followers ({count})',
        blank_slate: 'No followers yet',
        actions: {
          add: {
            button: 'Add',
            tooltip: 'Followers will receive a message when there are candidate updates.'
          },
          follow: 'Follow',
          unfollow: 'Unfollow',
          remove: {
            label: 'Remove follower'
          }
        }
      },
      video_interview: {
        native: {
          title: 'Video Interview',
          view_all: 'View all'
        },
        third_party: {
          title: 'Video Interview',
          view_all: 'View all',
          view_on: 'View on {providerName}'
        }
      },
      assessment: {
        title: 'Assessment',
        view_all: 'View all',
        native: {
          overall_score: 'Total average score'
        },
        third_party: {
          total_score: 'Total score',
          view_report: 'View report on {providerName}'
        }
      }
    },
    sections: {
      profile: {
        details: {
          contact_details: {
            subsections: {
              address: {title: 'Address'},
              email: {title: 'Email'},
              phone: {title: 'Phone'}
            }
          },
          evaluation_labels: {
            labels: {
              competence: 'Competency',
              referral: 'Referral',
              candidate_left: 'Past favorite',
              assessment: 'Good assessment',
              positive_evaluation: 'Positive comments',
              culture_fit: 'Culture fit',
              skills_matching: 'Skills',
              education_matching: 'Education',
              experience_matching: 'Experience'
            },
            sentences: {
              summary: 'AI Recruiter suggests {candidate} could be a good match for your job. ',
              competence: 'Overall, they are a good match for experience, skills and expertise. ',
              referral: '{referrer} referred them for a past job. ',
              candidate_left: 'In the past, they showed promise but withdrew from the hiring process early. ',
              assessment: 'They achieved good results in previous assessments. ',
              positive_evaluation:
                'The hiring team made some positive comments about this person when recruiting for a previous role. ',
              culture_fit: 'It looks like they could be a good cultural fit for your company. ',
              skills_matching: '',
              education_matching: '',
              experience_matching: '',
              suggested: {
                single_label: 'AI Recruiter suggested {candidate} because they look like a good match.',
                and: 'and',
                multiple_labels:
                  "AI Recruiter suggested {candidate} because they match this job's {labels} requirements."
              }
            },
            unknown_referrer: 'A colleague'
          },
          linkedin_profile_url: {
            prefix: 'View the',
            url_label: 'LinkedIn Recruiter version of this candidate profile'
          },
          privacy_settings: {
            subsections: {
              texting_consent: {
                title: 'Texting',
                status_label: {
                  declined: 'Disabled - no consent',
                  forced: 'Enabled - consent unconfirmed',
                  granted: 'Enabled - consent confirmed',
                  pending: 'Disabled - consent pending',
                  suspended: 'Disabled - no consent'
                }
              }
            }
          },
          resume: {
            anonymized_missing: "This resume couldn't be anonymized.",
            anonymized_missing_action: 'View resume with identifiable information'
          },
          social_profiles: {
            anonymized_hint: 'Social profiles are hidden when candidates are anonymized',
            explanation: 'These profiles were automatically retrieved, not provided by the candidate.'
          }
        }
      }
    }
  },
  common: {
    yes: 'Yes',
    no: 'No',
    na: 'n/a',
    prepositions: {
      at: 'at',
      in: 'in'
    },
    files: {
      upload: 'Upload a file',
      replace: 'Replace file',
      description: 'or drag and drop here',
      size_limit: 'File size limit of {limit}MB exceeded',
      type_not_supported: 'File type not supported',
      upload_cancelled: 'Cancelled',
      register_attachment_failed: 'File upload failed. Failed to register attachment',
      unknown_error: 'File upload failed'
    },
    date: {
      now: 'Now',
      na: 'n/a',
      year_one: 'year',
      year_other: 'years',
      month_one: 'month',
      month_other: 'months',
      day_one: 'day',
      day_other: 'days',
      distance: {
        year: '{count} years ago',
        month: '{count} months ago',
        day: '{count} days ago',
        hour: '{count} hours ago',
        minute: '{count} minutes ago'
      },
      adverbs: {
        about: {
          distance: {
            year: 'About {count} years ago',
            month: 'About {count} months ago',
            day: 'About {count} days ago',
            hour: 'About {count} hours ago',
            minute: 'About {count} minutes ago'
          }
        },
        almost: {
          distance: {
            year: 'Almost {count} years ago',
            month: 'Almost {count} months ago',
            day: 'Almost {count} days ago',
            hour: 'Almost {count} hours ago',
            minute: 'Almost {count} minutes ago'
          }
        },
        less_than: {
          distance: {
            year: 'Less than {count} years ago',
            month: 'Less than {count} months ago',
            day: 'Less than {count} days ago',
            hour: 'Less than {count} hours ago',
            minute: 'Less than {count} minutes ago'
          }
        },
        over: {
          distance: {
            year: 'Over {count} years ago',
            month: 'Over {count} months ago',
            day: 'Over {count} days ago',
            hour: 'Over {count} hours ago',
            minute: 'Over {count} minutes ago'
          }
        }
      }
    }
  }
} as const;
