import setDefaultDynamicTexts from 'shared/ui/helpers/locales/setDefaultDynamicTexts';
import withDynamicTexts from 'shared/ui/helpers/locales/withDynamicTexts';
import en from './en';

const dynamicTranslations = Object.freeze({
  candidate_card: {
    rating: {
      breakdown_tooltip: {
        title: {
          evaluation_count: ({count}: {count: number}) => `${count} ${count === 1 ? 'Evaluation' : 'Evaluations'}`
        }
      },
      simple: {
        thumbs: {
          a18y_label: ({score}: {score: 0 | 1 | 2}) => {
            switch (score) {
              case 2:
                return 'definite';
              case 1:
                return 'positive';
              default:
                return 'negative';
            }
          }
        },
        stars: {
          a18y_label: ({score, total = 5}: {score: number; total?: number}) => `${score} out of ${total} stars`
        },
        numbers: {
          a18y_label: ({score, total = 10}: {score: number; total?: number}) => `${score} out of ${total}`
        }
      }
    },
    siblings: {
      dropdown: {
        btn_title: ({count}: {count: number}) => `${count} ${count === 1 ? 'other job' : 'other jobs'}`,
        header: {
          title: ({count}: {count: number}) => `${count === 1 ? 'Other job' : `Other jobs`}`,
          subtitle: ({name, count}: {name: string; count: number}) =>
            `${name} is also in the following ${count === 1 ? `job:` : `jobs:`}`
        }
      }
    }
  },
  candidate_profile: {
    avatar: ({type}: {type: string}) => `Photo from ${type}`,
    candidate_overview: {
      siblings: {
        header: {
          title: ({count}: {count: number}) => `${count === 1 ? 'Other job' : `Other jobs (${count})`}`,
          subtitle: ({name, count}: {name: string; count: number}) =>
            `${name} is also in the following ${count === 1 ? `job:` : `jobs:`}`
        }
      }
    },
    evaluation: {
      more_members: {
        other: ({count}: {count: number}) => `and ${count} more members`
      }
    },
    sections: {
      profile: {
        details: {
          evaluation_labels: {
            sentences: {
              summary: ({candidate}: {candidate: string}) =>
                `AI Recruiter suggests ${candidate} could be a good match for your job. `,
              referral: ({referrer}: {referrer: string}) => `${referrer} referred them for this job. `,
              suggested: {
                single_label: ({candidate}: {candidate: string}) =>
                  `AI Recruiter suggested ${candidate} because they look like a good match.`,
                multiple_labels: ({candidate, labels}: {candidate: string; labels: string}) =>
                  `AI Recruiter suggested ${candidate} because they match this job's ${labels} requirements.`
              }
            }
          }
        }
      }
    }
  },
  common: {
    files: {
      size_limit: ({limit}: {limit: number}) => `File size limit of ${limit}MB exceeded`
    },
    date: {
      distance: {
        year: ({count}: {count: number}) => `${count} ${count === 1 ? 'year' : 'years'} ago`,
        month: ({count}: {count: number}) => `${count} ${count === 1 ? 'month' : 'months'} ago`,
        day: ({count}: {count: number}) => `${count} ${count === 1 ? 'day' : 'days'} ago`,
        hour: ({count}: {count: number}) => `${count} ${count === 1 ? 'hour' : 'hours'} ago`,
        minute: ({count}: {count: number}) => `${count} ${count === 1 ? 'minute' : 'minutes'} ago`
      },
      adverbs: {
        about: {
          distance: {
            year: ({count}: {count: number}) => `About ${count} ${count === 1 ? 'year' : 'years'} ago`,
            month: ({count}: {count: number}) => `About ${count} ${count === 1 ? 'month' : 'months'} ago`,
            day: ({count}: {count: number}) => `About ${count} ${count === 1 ? 'day' : 'days'} ago`,
            hour: ({count}: {count: number}) => `About ${count} ${count === 1 ? 'hour' : 'hours'} ago`,
            minute: ({count}: {count: number}) => `About ${count} ${count === 1 ? 'minute' : 'minutes'} ago`
          }
        },
        almost: {
          distance: {
            year: ({count}: {count: number}) => `Almost ${count} ${count === 1 ? 'year' : 'years'} ago`,
            month: ({count}: {count: number}) => `Almost ${count} ${count === 1 ? 'month' : 'months'} ago`,
            day: ({count}: {count: number}) => `Almost ${count} ${count === 1 ? 'day' : 'days'} ago`,
            hour: ({count}: {count: number}) => `Almost ${count} ${count === 1 ? 'hour' : 'hours'} ago`,
            minute: ({count}: {count: number}) => `Almost ${count} ${count === 1 ? 'minute' : 'minutes'} ago`
          }
        },
        less_than: {
          distance: {
            year: ({count}: {count: number}) => `Less than ${count} ${count === 1 ? 'year' : 'years'} ago`,
            month: ({count}: {count: number}) => `Less than ${count} ${count === 1 ? 'month' : 'months'} ago`,
            day: ({count}: {count: number}) => `Less than ${count} ${count === 1 ? 'day' : 'days'} ago`,
            hour: ({count}: {count: number}) => `Less than ${count} ${count === 1 ? 'hour' : 'hours'} ago`,
            minute: ({count}: {count: number}) => `Less than ${count} ${count === 1 ? 'minute' : 'minutes'} ago`
          }
        },
        over: {
          distance: {
            year: ({count}: {count: number}) => `Over ${count} ${count === 1 ? 'year' : 'years'} ago`,
            month: ({count}: {count: number}) => `Over ${count} ${count === 1 ? 'month' : 'months'} ago`,
            day: ({count}: {count: number}) => `Over ${count} ${count === 1 ? 'day' : 'days'} ago`,
            hour: ({count}: {count: number}) => `Over ${count} ${count === 1 ? 'hour' : 'hours'} ago`,
            minute: ({count}: {count: number}) => `Over ${count} ${count === 1 ? 'minute' : 'minutes'} ago`
          }
        }
      }
    }
  }
});

type isFunction<T> = T extends (...args: any[]) => string ? true : false;
type isString<T> = T extends string ? true : false;
type IsValue<T, U> = (isFunction<T> & isFunction<U>) | (isString<T> & isString<U>) extends true ? true : false;

type DeepMergeTranslations<Translations, DynamicTranslations> =
  IsValue<Translations, DynamicTranslations> extends true
    ? DynamicTranslations
    : {
        [TranslationKey in keyof Translations]: TranslationKey extends keyof DynamicTranslations
          ? DeepMergeTranslations<Translations[TranslationKey], DynamicTranslations[TranslationKey]>
          : Translations[TranslationKey];
      } & DynamicTranslations;

export type Translations = DeepMergeTranslations<Readonly<typeof en>, typeof dynamicTranslations>;

const translations = setDefaultDynamicTexts(en, dynamicTranslations) as Translations;

export const withDynamicTranslations = (texts: Translations = translations): Translations =>
  withDynamicTexts(texts, translations) as Translations;

export type EvaluationLabel = keyof typeof en.candidate_profile.sections.profile.details.evaluation_labels.labels;
export type EvaluationSentencesLabel =
  keyof typeof en.candidate_profile.sections.profile.details.evaluation_labels.sentences;

export default translations;
